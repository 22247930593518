<template>
  <bm-point-collection :points="getCurrentPoints()" :shape="shapeType" :color="colorType" :size="mapPointSizeType" @click="collectionPoleClick"></bm-point-collection>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import {
  BmPointCollection,
} from '@/components/vue-baidu-map-v3/components'
export default {
  components: {
    BmPointCollection
  },
  props: {
    typeData: {
      type: Object,
      default() {
        return {
          color: 0,//红色0 绿色1 灰色2 黄色3
          type: '',
          arr: [],
        }
      }
    },
    zoom: {
      type: Number,
      default: 18,
    },
    mapItemList: {
      type: Array,
      default() {
        return []
      }
    },
  },
  watch: {
    mapItemList() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.loadingData();
      this.loadPointsInBatches();
    },
    zoom() {
      this.mapZoomChanged();
      this.loadPointsInBatches();
    },
  },
  data() {
    return {
      poleData: [],
      stationData: [],
      luxData: [],
      colorType: '#728aa8',
      shapeType: 'BMAP_POINT_SHAPE_CIRCLE',
      mapPointSizeType: 'BMAP_POINT_SIZE_SMALL',
      mapPointSizeTypeBig: 'BMAP_POINT_SIZE_SMALL',
      batchData: [],
      pointBatches: [], // 存放分批后的点数据
      loadedBatches: 0, // 已加载的批次数量
      timeoutId: null,
      batchSize: 4000,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  mounted() {
    this.loadingData()
  },
  methods: {
    getCurrentPoints() {
      let points = [];
      for (let i = 0; i < this.loadedBatches; i++) {
        points = points.concat(this.pointBatches[i]);
      }
      return points;
    },
    loadPointsInBatches() {
      this.pointBatches = [];
      // 批次划分数据
      for (let i = 0; i < Math.ceil(this.mapItemList.length / this.batchSize); i++) {
        const start = i * this.batchSize;
        const end = Math.min(start + this.batchSize, this.mapItemList.length);
        this.pointBatches.push(this.mapItemList.slice(start, end));
      }
      this.loadNextBatch();
    },
    loadNextBatch() {
      if (this.loadedBatches < this.pointBatches.length) {
        this.timeoutId = setTimeout(() => {
          this.loadedBatches++;
          this.loadNextBatch(); // 递归调用以加载下一批
        }, 200);
      }
    },
    collectionPoleClick({ point }) {
      let item = {};
      if (point.id == undefined) {
        item = this.mapItemList.filter(p => p.lat == point.lat && p.lng == point.lng)[0]
      } else {
        item = this.mapItemList.filter(p => p.id == point.id)[0]
      }
      this.$emit('collectionClick', { item, type: 'pole' });
    },
    collectionStationClick({ point }) {
      let item = {};
      if (point.id == undefined) {
        item = this.mapItemList.filter(p => p.lat == point.lat && p.lng == point.lng)[0]
      } else {
        item = this.mapItemList.filter(p => p.id == point.id)[0]
      }
      this.$emit('collectionClick', { item, type: 'station' });
    },
    loadingData() {
      switch (this.typeData.type) {
        case 'box':
          this.shapeType = 'BMAP_POINT_SHAPE_STAR'
          break;
        case 'pole':
          this.shapeType = 'BMAP_POINT_SHAPE_CIRCLE'
          break;
        case 'lux':
          this.shapeType = 'BMAP_POINT_SHAPE_WATERDROP'
          break;
      }
      switch (this.typeData.color) {//  color: 0,//红色0 绿色1 灰色2 黄色3
        case 0:
          this.colorType = '#f24848'
          break;
        case 1:
          this.colorType = '#09dd80'
          break;
        case 2:
          this.colorType = '#09dd80'
          break;
        case 3:
          this.colorType = '#ffcc1f'
          break;
      }
    },
    mapZoomChanged() {
      if (this.zoom > 0 && this.zoom < 10) {
        this.mapPointSizeType = 'BMAP_POINT_SIZE_TINY'
        this.mapPointSizeTypeBig = 'BMAP_POINT_SIZE_SMALL'
      } else if (this.zoom > 9 && this.zoom < 13) {
        this.mapPointSizeType = 'BMAP_POINT_SIZE_SMALLER'
        this.mapPointSizeTypeBig = 'BMAP_POINT_SIZE_NORMAL'
      } else if (this.zoom > 12 && this.zoom < 15) {
        this.mapPointSizeType = 'BMAP_POINT_SIZE_SMALL'
        this.mapPointSizeTypeBig = 'BMAP_POINT_SIZE_BIG'
      } else {
        this.mapPointSizeType = 'BMAP_POINT_SIZE_NORMAL'
        this.mapPointSizeTypeBig = 'BMAP_POINT_SIZE_BIGGER'
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timeoutId);
  },
}
</script>

<style>
.map-tool-container {
  display: flex;
  border-radius: 3px;
  background-color: white;
  height: 26px;
}
.map-tool-container .item {
  height: 26px;
  background-color: white;
  color: black;
  cursor: pointer;
  border: solid 1px #1b5fa8;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding: 0 5px;
}
.map-tool-container .active {
  background-color: #1b5fa8;
  color: white;
}
</style>