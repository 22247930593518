<template>
  <div class="pole-main">
    <div id="mapGL" style="height: 100%;width: 100%;" ref="divStyle" :key="mapGLIndex"></div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import { BMPGL } from '@/plugins/bmpgl'
import longgang from './longgang.json'

export default {
  name: 'MapComponent',
  components: {
    // BMap
  },
  data() {
    return {
      mapgl: null,
      cluster: null,
      Positions: [],
      gisData: [],
      leyZoom:0,
      mapGLIndex:0,
      ak: 'MhPU9AQpbKZ0kyQf7bWAv7BvwoKOax88',
      center: { lng: 116.404, lat: 39.915 }, // 地图中心点经纬度
      zoom: 20, // 地图缩放级别
      styleJson: require('@/assets/custom_map_config_bak.json'),
      // styleJson: require('@/assets/custom_map_config.json'),
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'organs']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
    ...mapState('cmd', ['windowOnResize']),
  },
   watch: {
    windowOnResize() {
      this.mapResize();
    }
  },
  created: function () {
  },
  mounted: function () {
    this.initMap2();
  },
  methods: {
    initMap() {
      BMPGL().then((BMapGL) => {
        let bmap = new BMapGL.Map("mapGL");
        bmap.centerAndZoom(new BMapGL.Point(114.25375035, 22.72128826), 18);  // 初始化地图,设置中心点坐标和地图级别
        bmap.enableScrollWheelZoom(true);     // 开启鼠标滚轮缩放
        bmap.setTilt(50);      // 设置地图初始倾斜角
        var keyFrames = [
          {
            center: new BMapGL.Point(116.307092, 40.054922),
            zoom: 20,
            tilt: 50,
            heading: 0,
            percentage: 0
          },
          {
            center: new BMapGL.Point(116.307631, 40.055391),
            zoom: 20,
            tilt: 70,
            heading: 0,
            percentage: 0.1
          },
          {
            center: new BMapGL.Point(116.306858, 40.057887),
            zoom: 20,
            tilt: 70,
            heading: 0,
            percentage: 0.25
          },
          {
            center: new BMapGL.Point(116.306858, 40.057887),
            zoom: 20,
            tilt: 70,
            heading: -90,
            percentage: 0.35
          },
          {
            center: new BMapGL.Point(116.307904, 40.058118),
            zoom: 20,
            tilt: 70,
            heading: -90,
            percentage: 0.45
          },
          {
            center: new BMapGL.Point(116.307904, 40.058118),
            zoom: 20,
            tilt: 70,
            heading: -180,
            percentage: 0.55
          },
          {
            center: new BMapGL.Point(116.308902, 40.055954),
            zoom: 20,
            tilt: 70,
            heading: -180,
            percentage: 0.75
          },
          {
            center: new BMapGL.Point(116.308902, 40.055954),
            zoom: 20,
            tilt: 70,
            heading: -270,
            percentage: 0.85
          },
          {
            center: new BMapGL.Point(116.307779, 40.055754),
            zoom: 20,
            tilt: 70,
            heading: -360,
            percentage: 0.95
          },
          {
            center: new BMapGL.Point(116.307092, 40.054922),
            zoom: 20,
            tilt: 50,
            heading: -360,
            percentage: 1
          },
        ];
        var opts = {
          duration: 10000,
          delay: 3000,
          interation: 'INFINITE'//循环次数 
        };
        // 声明动画对象
        var animation = new BMapGL.ViewAnimation(keyFrames, opts);
        // 监听事件
        // animation.addEventListener('animationstart', function (e) { console.log(e) });
        // animation.addEventListener('animationiterations', function (e) { console.log(e) });
        // animation.addEventListener('animationend', function (e) { console.log(e) });
        // setTimeout(function () {

        // }, 1000);
        bmap.startViewAnimation(animation);
      })
    },
    queryGisItem() {
      let bounds = this.map.getBounds()
      let ajaxData = {
        "type": -1,
        "stationId": 0,
        "groupId": 0,
        latmin: bounds.fe,
        latmax: bounds.de,
        lngmin: bounds.Te,
        lngmax: bounds.Ne,
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryGisItemNew`, ajaxData).then(res => {
        this.gisData = [];
        if (res.code == 0) {
          res.data.forEach(el => {
            this.gisData.push({ lnglat: this.bd_encrypt(el.lng, el.lat), con: el })
          });
        }
      });
    },
    isIcon(data) {
      let imgUrl = '';
      switch (data.type) {//0配电箱，299光控，10线缆，14灯杆  img.powerbox.status.stop
        case 0:
          if (data.online) {//在线
            imgUrl = this.img.powerbox.status2.online //
          } else {
            if (data.alarm) {//报警
              if (data.running) {//通电
                imgUrl = this.img.powerbox.status2.fetal
              } else {
                imgUrl = this.img.powerbox.status2.fetalNo
              }
            } else {//不报警
              if (data.running) {//通电
                imgUrl = this.img.powerbox.status2.online
              } else {
                imgUrl = this.img.powerbox.status2.offline
              }
            }
          }
          break;
        case 10:

          break;
        case 14:
          if (data.online) {//在线
            imgUrl = this.img.light.carat.gis.LightsOn
          } else {
            if (data.alarm) {//报警
              if (data.running) {//通电
                imgUrl = this.img.light.carat.gis.LightsPolice
              } else {
                imgUrl = this.img.light.carat.gis.LightsOutPolice
              }
            } else {//不报警
              if (data.running) {//通电 
                imgUrl = this.img.light.carat.gis.LightsOut
              } else {
                imgUrl = this.img.light.carat.gis.LightsLine   //离线
              }
            }
          }
          break;
        case 299:

          break;
      }
      return imgUrl
    },
    initMap2() {
      BMPGL().then((BMapGL) => {
        // map.setHeading(64.5);   //设置地图旋转角度
        // map.setTilt(73);       //设置地图的倾斜角度

        // // 禁止地图旋转和倾斜可以通过配置项进行设置
        // var map = new BMapGL.Map("allmap",{
        //     enableRotate: false,
        //     enableTilt: false
        // });
        this.mapgl = new BMapGL.Map('mapGL' ,{backgroundColor: [0, 0, 0, 0.5]});
        this.mapgl.enableScrollWheelZoom(true);// 开启鼠标滚轮缩放
        var point = new BMapGL.Point(114.253750, 22.72128);
        // this.mapgl.opacity(0)options fillOpacity
        this.mapgl.centerAndZoom(point, 13);
        const areas = []

        for (let i = 0; i < longgang.features.length; i++) {
          let villages = []
          const villagesPoints = longgang.features[i].geometry.coordinates[0][0]
          for (let j = 0; j < villagesPoints.length; j++) {
            villages.push(villagesPoints[j].toString())
          }
          areas.push(villages)
        }
        for (let z = 0; z < areas.length; z++) {
          let poly = [];
          for (let n = 0; n < areas[z].length; n++) {
            let villages = []
            villages = areas[z][n].split(",");
            poly.push(new BMapGL.Point(villages[0], villages[1]));
          }
          let border = new BMapGL.Prism(poly, 1000, {
            topFillColor:'#a6caed',// this.hexAdd('5679ea',z),
            topFillOpacity: 0.5,
            sideFillColor:'#0e4670',// this.hexAdd('5679ea',z),
            sideFillOpacity: 0.9
          });
          let this_ = this
          border.addEventListener('click', function () {
            this_.showInfo(z)
          });
          this.mapgl.addOverlay(border);
        }

        var styleJson = [{
          "featureType": "land",
          "elementType": "geometry",
          "stylers": {
            "color": "#242f3eff"
          }
        }, {
          "featureType": "manmade",
          "elementType": "geometry",
          "stylers": {
            "color": "#242f3eff"
          }
        }, {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": {
            "color": "#17263cff"
          }
        }, {
          "featureType": "road",
          "elementType": "geometry.fill",
          "stylers": {
            "color": "#9e7d60ff"
          }
        }, {
          "featureType": "road",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#554631ff"
          }
        }, {
          "featureType": "districtlabel",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#d69563ff"
          }
        }, {
          "featureType": "districtlabel",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#17263cff",
            "weight": 3
          }
        }, {
          "featureType": "poilabel",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#d69563ff"
          }
        }, {
          "featureType": "poilabel",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#17263cff",
            "weight": 3
          }
        }, {
          "featureType": "subway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "railway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "poilabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "subwaylabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "subwaylabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "tertiarywaysign",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "tertiarywaysign",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "provincialwaysign",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "provincialwaysign",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "nationalwaysign",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "nationalwaysign",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "highwaysign",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "highwaysign",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "green",
          "elementType": "geometry",
          "stylers": {
            "color": "#263b3eff"
          }
        }, {
          "featureType": "nationalwaysign",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#d0021bff"
          }
        }, {
          "featureType": "nationalwaysign",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#ffffffff"
          }
        }, {
          "featureType": "city",
          "elementType": "labels",
          "stylers": {
            "visibility": "on"
          }
        }, {
          "featureType": "city",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "city",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#d69563ff"
          }
        }, {
          "featureType": "city",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#17263cff"
          }
        }, {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#d69563ff"
          }
        }, {
          "featureType": "water",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#242f3eff"
          }
        }, {
          "featureType": "local",
          "elementType": "geometry.fill",
          "stylers": {
            "color": "#38414eff"
          }
        }, {
          "featureType": "local",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#ffffff00"
          }
        }, {
          "featureType": "fourlevelway",
          "elementType": "geometry.fill",
          "stylers": {
            "color": "#38414eff"
          }
        }, {
          "featureType": "fourlevelway",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#ffffff00"
          }
        }, {
          "featureType": "tertiaryway",
          "elementType": "geometry.fill",
          "stylers": {
            "color": "#38414eff"
          }
        }, {
          "featureType": "tertiaryway",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#ffffff00"
          }
        }, {
          "featureType": "tertiaryway",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#759879ff"
          }
        }, {
          "featureType": "fourlevelway",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#759879ff"
          }
        }, {
          "featureType": "highway",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#759879ff"
          }
        }, {
          "featureType": "highway",
          "elementType": "geometry.fill",
          "stylers": {
            "color": "#9e7d60ff"
          }
        }, {
          "featureType": "highway",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#554631ff"
          }
        }, {
          "featureType": "provincialway",
          "elementType": "geometry.fill",
          "stylers": {
            "color": "#9e7d60ff"
          }
        }, {
          "featureType": "provincialway",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#554631ff"
          }
        }, {
          "featureType": "tertiaryway",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#1a2e1cff"
          }
        }, {
          "featureType": "fourlevelway",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#1a2e1cff"
          }
        }, {
          "featureType": "highway",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#1a2e1cff"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#1a2e1cff"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#759879ff"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "geometry.fill",
          "stylers": {
            "color": "#9e7d60ff"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#554631ff"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "geometry.fill",
          "stylers": {
            "color": "#9e7d60ff"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#554631ff"
          }
        }, {
          "featureType": "arterial",
          "elementType": "geometry.fill",
          "stylers": {
            "color": "#9e7d60ff"
          }
        }, {
          "featureType": "arterial",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#554631fa"
          }
        }, {
          "featureType": "medicallabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "medicallabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "entertainmentlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "entertainmentlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "estatelabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "estatelabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "businesstowerlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "businesstowerlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "companylabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "companylabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "governmentlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "governmentlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "restaurantlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "restaurantlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "hotellabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "hotellabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "shoppinglabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "shoppinglabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "lifeservicelabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "lifeservicelabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "carservicelabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "carservicelabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "financelabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "financelabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "otherlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "otherlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "airportlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "on"
          }
        }, {
          "featureType": "airportlabel",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#d69563ff"
          }
        }, {
          "featureType": "airportlabel",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#17263cff"
          }
        }, {
          "featureType": "airportlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "highway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "6"
          }
        }, {
          "featureType": "highway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "7"
          }
        }, {
          "featureType": "highway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "8"
          }
        }, {
          "featureType": "highway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "9"
          }
        }, {
          "featureType": "highway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "10"
          }
        }, {
          "featureType": "highway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "6"
          }
        }, {
          "featureType": "highway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "7"
          }
        }, {
          "featureType": "highway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "8"
          }
        }, {
          "featureType": "highway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "9"
          }
        }, {
          "featureType": "highway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "10"
          }
        }, {
          "featureType": "nationalway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "6"
          }
        }, {
          "featureType": "nationalway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "7"
          }
        }, {
          "featureType": "nationalway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "8"
          }
        }, {
          "featureType": "nationalway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "9"
          }
        }, {
          "featureType": "nationalway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "10"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "6"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "7"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "8"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "9"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "10"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "6"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "7"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "8"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "9"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "10"
          }
        }, {
          "featureType": "highway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "6"
          }
        }, {
          "featureType": "highway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "7"
          }
        }, {
          "featureType": "highway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "8"
          }
        }, {
          "featureType": "highway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "9"
          }
        }, {
          "featureType": "highway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "10"
          }
        }, {
          "featureType": "provincialway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "8,9",
            "level": "8"
          }
        }, {
          "featureType": "provincialway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "8,9",
            "level": "9"
          }
        }, {
          "featureType": "provincialway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "8,9",
            "level": "8"
          }
        }, {
          "featureType": "provincialway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "8,9",
            "level": "9"
          }
        }, {
          "featureType": "provincialway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "8,9",
            "level": "8"
          }
        }, {
          "featureType": "provincialway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "8,9",
            "level": "9"
          }
        }, {
          "featureType": "cityhighway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "6"
          }
        }, {
          "featureType": "cityhighway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "7"
          }
        }, {
          "featureType": "cityhighway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "8"
          }
        }, {
          "featureType": "cityhighway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "9"
          }
        }, {
          "featureType": "cityhighway",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "10"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "6"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "7"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "8"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "9"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "10"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "6"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "7"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "8"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "9"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "6,10",
            "level": "10"
          }
        }, {
          "featureType": "arterial",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "9,10",
            "level": "9"
          }
        }, {
          "featureType": "arterial",
          "stylers": {
            "curZoomRegionId": "0",
            "curZoomRegion": "9,10",
            "level": "10"
          }
        }, {
          "featureType": "arterial",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "9,10",
            "level": "9"
          }
        }, {
          "featureType": "arterial",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "9,10",
            "level": "10"
          }
        }, {
          "featureType": "arterial",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "9,10",
            "level": "9"
          }
        }, {
          "featureType": "arterial",
          "elementType": "labels",
          "stylers": {
            "visibility": "off",
            "curZoomRegionId": "0",
            "curZoomRegion": "9,10",
            "level": "10"
          }
        }, {
          "featureType": "building",
          "elementType": "geometry.topfill",
          "stylers": {
            "color": "#2a3341ff"
          }
        }, {
          "featureType": "building",
          "elementType": "geometry.sidefill",
          "stylers": {
            "color": "#313b4cff"
          }
        }, {
          "featureType": "building",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#1a212eff"
          }
        }, {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#759879ff"
          }
        }, {
          "featureType": "road",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#1a2e1cff"
          }
        }, {
          "featureType": "provincialway",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#759879ff"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#759879ff"
          }
        }, {
          "featureType": "arterial",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#759879ff"
          }
        }, {
          "featureType": "provincialway",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#1a2e1cff"
          }
        }, {
          "featureType": "cityhighway",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#1a2e1cff"
          }
        }, {
          "featureType": "arterial",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#1a2e1cff"
          }
        }, {
          "featureType": "local",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "manmade",
          "elementType": "labels.text.fill",
          "stylers": {
            "color": "#d69563ff"
          }
        }, {
          "featureType": "manmade",
          "elementType": "labels.text.stroke",
          "stylers": {
            "color": "#17263cff"
          }
        }, {
          "featureType": "subwaystation",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "transportationlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "transportationlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "estate",
          "elementType": "geometry",
          "stylers": {
            "color": "#2a3341ff"
          }
        }];
        console.log(styleJson+"'")
        this.mapgl.setMapStyleV2({ styleJson: styleJson });
        // this.mapgl.setMapStyleV2(styleJson);
        // setTimeout(() => {
        //   // this.$refs.divStyle.style.backgroundImage = 'none'
        //   let aaa=  document.getElementById('mapGL');
        //   aaa.style.backgroundImage = 'none';
        // }, 6000);
        // document.getElementById('mapGL').style.backgroundImage = 'none'
        // this.mapGLIndex++;
        let bdary = new BMapGL.Boundary();
        this.showBoundary(bdary);
      })
    },
    showBoundary(bdary){
      console.log(bdary)
      // bdary.get
    },
    hexAdd(hex,index) {
      let num = parseInt(hex, 16);
      let result = (num + 320*index).toString(16);
      return '#'+result;
    },
    mapResize(){
      this.mapgl.resize();
    },
    showInfo(index) {
      // case 'mouseover':
      //     e.target.setTopFillColor('#475fab');
      //     e.target.setTopFillOpacity(1);
      //     break;
      // case 'mouseout':
      //     e.target.setTopFillColor('#5679ea');
      //     e.target.setTopFillOpacity(0.5);
      //     break;
      // console.log(longgang.features[index].properties.name);
      alert(longgang.features[index].properties.name);
    }
  },
}
</script>
<style scoped>
.pole-main {
  width: 100%;
  height: 100%;

}
#mapGL{
  /* background-image: none!important; */
}
</style>