<template>
  <div class="pole-main">
    <baidu-map class="map" :ak="ak" :center="center" :map-type="mapMenuItem.type" :double-click-zoom="false" @ready="mapReady" :zoom="center.zoom" @zoomend="mapZoomChanged" @dragend="dragend" :min-zoom="levelSet.min" :scroll-wheel-zoom="true">
      <template v-if="map">
        <template v-if="center.zoom > 17">
          <template v-for="(gisItem, gisidx) in gisData">
            <MapPowerItem v-if="gisItem.type == 0" :item="gisItem" :key="gisidx"></MapPowerItem>
            <MapPoleItem v-if="gisItem.type == 14" :item="gisItem" :key="gisidx"></MapPoleItem>
          </template>
        </template>
        <template>
          <template v-for="(item, index) in poleData">
            <MenuCollection :zoom='center.zoom' :mapItemList='item.arr' :typeData='item' :key="'pole-coll'+index" v-if="item.arr.length>0">
            </MenuCollection>
          </template>
          <template v-for="(item, index) in stationData">
            <MenuCollection :zoom='center.zoom' :mapItemList='item.arr' :typeData='item' :key="'box-coll'+index" v-if="item.arr.length>0">
            </MenuCollection>
          </template>
        </template>
      </template>
    </baidu-map>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { BaiduMap } from '@/components/vue-baidu-map-v3/components'
import MapPowerItem from '../mapNew/MapPowerItem'
import MapPoleItem from '../mapNew/MapPoleItem'
import MenuCollection from './MenuCollection'

export default {
  name: 'HomeGis22',
  components: {
    BaiduMap,
    MapPowerItem,
    MapPoleItem,
    MenuCollection,
    // BmLabel,
    // BmNavigation,
    // BmMarker,
  },
  data() {
    return {
      map: null,
      BMap: null,
      mapStyle: {
        styleJson: require('@/views/customized/public/customized_ver1.json')
      },
      ak: 'MhPU9AQpbKZ0kyQf7bWAv7BvwoKOax88',
      center: { lng: 113.828333, lat: 22.770866, zoom: 14 },
      levelSet: {
        min: 6, //小于此等级时
        max: 20 //小于此等级时
      },
      mapMenuItem: {
        type: 'BMAP_NORMAL_MAP',
        offset: {
          width: 10,
          height: 10
        }
      },
      gisData: [],
      poleData: [],
      stationData: [],
      isZoomNum: true
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'organs']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {
  },
  created: function () {
  },
  mounted: function () {
    this.getGisItem();
  },
  methods: {
    mapReady: function (params) {
      this.map = params.map
      this.BMap = params.BMap
      this.map.setMapStyleV2({
        styleJson: this.mapStyle.styleJson
      })
      if (this.center && this.center.lat && this.center.lng) {
        this.center.lat = this.config.lat;
        this.center.lng = this.config.lng;
      }
    },
    mapZoomChanged(e) {
      if (!this.map) return
      this.center.zoom = e.target.getZoom()
      this.gisData = [];
      if (this.center.zoom > 17) {
        this.getGisPole()
      }
      if (this.isZoomNum) {
        this.getGisItem(true);
      }
    },
    dragend() {
      if (!this.map) return
      this.gisData = [];
      if (this.center.zoom > 17) {
        this.getGisPole()
      }
    },
    getGisPole() {//获取显示数据
      if (this.map == null) return
      let bounds = this.map.getBounds()
      let viewMapData = {
        latmin: bounds.getSouthWest().lat,
        latmax: bounds.getNorthEast().lat,
        lngmin: bounds.getSouthWest().lng,
        lngmax: bounds.getNorthEast().lng,
        groupId: 0,
        stationId: 0,
        type: -1
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryGisItemNew`, viewMapData).then(res => {
        console.log(res)
        this.$set(this, 'gisData', res.data);
      })
    },
    getGisItem(is) {//获取全部
      if (is) { this.isZoomNum = false }
      let ajaxData = {
        latmin: -90,
        latmax: 90,
        lngmin: -180,
        lngmax: 180,
        groupId: 0,
        stationId: 0,
        type: -1
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryGisItemNew`, ajaxData).then(res => {
        if (res.code == 0) {
          if (res.data.length == 0) return false;
          if (this.center && this.center.lat && this.center.lng) {
            this.center.lat = this.config.lat;
            this.center.lng = this.config.lng;
          }
          let stationData = [], poleData = [];
          res.data.forEach(el => {
            if (el.type == 0) {
              stationData.push({ lng: el.lng, lat: el.lat, id: el.id })
            } else if (el.type == 14) {
              poleData.push({ lng: el.lng, lat: el.lat, id: el.id })
            }
          })
          this.stationData = this.collectionHandleData(stationData, 'box')
          this.poleData = this.collectionHandleData(poleData, 'pole');
        }
      });
    },
    collectionHandleData(item, type) {
      let arr = [
        {
          color: 0,//红色0 绿色1 灰色2 黄色3
          type: type,
          arr: [],
        }, {
          color: 1,
          type: type,
          arr: [],
        }, {
          color: 2,
          type: type,
          arr: [],
        }, {
          color: 3,
          type: type,
          arr: [],
        }
      ]
      if (type == 'lux') {
        item.forEach(el => {
          if (!el.online) {
            arr[2].arr.push(el)
          } else if (el.alarm) {
            arr[0].arr.push(el)
          } else {
            arr[1].arr.push(el)
          }
        })
        return arr;
      }
      item.forEach(el => {
        if ((el.pcnt == 0 && type == 'pole') || el.status != 1 && type == 'box') {
          arr[2].arr.push(el)
        } else if (!el.online) {
          arr[2].arr.push(el)
        } else {
          if (el.alarm) {
            if (el.running) {
              arr[0].arr.push(el)
            } else {
              arr[3].arr.push(el)
            }
          } else {
            if (el.running) {
              arr[0].arr.push(el)
            } else {
              arr[2].arr.push(el)
            }
          }
        }
      })
      return arr;
    },
  },
}
</script>
<style scoped>
.pole-main {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
}
.map {
  width: 100%;
  height: 100%;
  margin: auto;
}
.map >>> .anchorBL {
  display: none;
}
</style>